.page-container .page-search-bar[data-v-03d6b036] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-container .page-search-bar .app-name[data-v-03d6b036] {
    width: 50%;
    text-align: left;
}
.page-container .page-search-bar .app-name span[data-v-03d6b036] {
      font-size: var(--rootFontSize);
}
.page-container .page-search-bar .app-tools[data-v-03d6b036] {
    width: 40%;
    text-align: right;
}
